"use client";

import { FC, useMemo } from "react";
import { useParams } from "next/navigation";
import ResultsLayout from "components/layout/sportsbook/results/ResultsLayout";
import { CommonLayout } from "components/layout/common/CommonLayout";

export const SportResultsLayoutContainer: FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const params = useParams();

    const getLayout = useMemo(() => {
        if (params?.resultsGameRoutes) {
            return <CommonLayout>{children}</CommonLayout>;
        } else {
            return <ResultsLayout>{children}</ResultsLayout>;
        }
    }, [params, children]);

    return <>{getLayout}</>;
};
