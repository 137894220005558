"use client";

import { TranslationScopes } from "@finbackoffice/enums";
import { useParams } from "next/navigation";
import { FC, PropsWithChildren, RefObject, useContext, useRef } from "react";
import {
    AuthContext,
    CasinoFavoritesContext,
    IModalForwardRefPropsV2,
    ModalsContextV2, // refactor
    useRuntimeConfig,
} from "@finbackoffice/site-core";
import MainContent from "components/MainContent";
import Img from "components/base/img/Img";
import Modal from "components/base/modal/Modal";
import Translate from "components/base/translate/Translate";
import Footer from "components/footer/Footer";
import CasinoFavoritesPanel from "components/pages/casino/favorites/CasinoFavoritesPanel";
import CasinoGameItem from "components/pages/casino/game-item/CasinoGameItem";
import { ModalTypes } from "utils/constants";
import styles from "./casino-layout.module.sass";

const CasinoLayout: FC<PropsWithChildren> = ({ children }) => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const params = useParams(); // refactor
    const { isUserLoggedIn } = useContext(AuthContext);
    const isGameView = !!params?.gameId; // refactor
    const showFavorites = !isGameView && isUserLoggedIn;
    const { favoriteGamesState, createCasinoFavorite, deleteCasinoFavorite } =
        useContext(CasinoFavoritesContext);
    const fasvoritesModalRef: RefObject<IModalForwardRefPropsV2 | null> = useRef(null);
    const { setCurrentModal } = useContext(ModalsContextV2); // refactor

    const onFavoritesModalHeaderClick = () => {
        setCurrentModal(null);
    };

    return (
        <MainContent>
            {children}
            {showFavorites && Boolean(favoriteGamesState.games.length) && (
                <>
                    <CasinoFavoritesPanel
                        games={favoriteGamesState.games}
                        openFavoritesModal={() => fasvoritesModalRef.current?.open()}
                        createCasinoFavorite={createCasinoFavorite}
                        deleteCasinoFavorite={deleteCasinoFavorite}
                    />
                    <Modal
                        ref={fasvoritesModalRef}
                        type={ModalTypes.CASINO_FAVORITES}
                        animateVariant="bottom"
                        styleClass={styles.casinoFavoritesModal}>
                        <>
                            <div
                                className={styles.favoritesModalHeader}
                                onClick={onFavoritesModalHeaderClick}>
                                <Img
                                    source={`${ASSETS_URL}/common/mobile/base-icons/casino-favorite-star.svg`}
                                    alt="Favorites"
                                    width={21}
                                    height={22}
                                    wrapperClassName={styles.favoritesBarIcon}
                                />
                                <Translate
                                    tid="casino_my_favorites"
                                    namespace={TranslationScopes.Casino}
                                />{" "}
                                ({favoriteGamesState.games.length})
                                <span className={styles.openFavoritesBtn} />
                            </div>
                            <div className={styles.casinoFavoritesModalGames}>
                                {!Boolean(favoriteGamesState.games.length) && (
                                    <div className="no-game">
                                        You have no Favorites at this moment
                                    </div>
                                )}
                                {favoriteGamesState.games.map((game) => (
                                    <CasinoGameItem key={game.id} game={game} />
                                ))}
                            </div>
                        </>
                    </Modal>
                </>
            )}

            {!isGameView && <Footer />}
        </MainContent>
    );
};

export default CasinoLayout;
