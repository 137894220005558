"use client";

import { FC, useMemo } from "react";
import { useParams } from "next/navigation";
import { CommonLayout } from "components/layout/common/CommonLayout";
import { SportLiveLayout } from "./SportLiveLayout";

export const SportLiveLayoutContainer: FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const params = useParams();

    const getLayout = useMemo(() => {
        if (params?.liveGameRoutes && params?.liveGameRoutes?.length > 0) {
            return <CommonLayout>{children}</CommonLayout>;
        } else {
            return <SportLiveLayout>{children}</SportLiveLayout>;
        }
    }, [params, children]);

    return <>{getLayout}</>;
};
