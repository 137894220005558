"use client";

import { FC } from "react";
import Header from "components/header/Header";
import Menu from "components/menu/Menu";

export const MainLayout: FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    return (
        <>
            <Header />
            {children}
            <Menu />
        </>
    );
};
