"use client";

import { FC } from "react";
import { usePathname } from "next/navigation";
import { RouteNames } from "utils/constants";
import { AccountCommonLayout } from "components/layout/common/AccountCommonLayout";

export const AccountLayoutContainer: FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const pathname = usePathname();

    return (
        <>
            {!pathname?.includes(RouteNames.BETS) && (
                <AccountCommonLayout>{children}</AccountCommonLayout>
            )}
            {pathname?.includes(RouteNames.BETS) && children}
        </>
    );
};
